import {createStore} from "redux";
import {Provider} from "react-redux";

import React, {Component} from "react";
import MapView from "./components/MapView";

import rootReducer from "./reducers";
import { MESHDB_URL } from "./actions";

const store = createStore(rootReducer);

class Map extends Component {
    state = {
        match: {params: {nodeId: ""}},
    }

    constructor(props) {
        super(props);
        window.addEventListener("message", ({ data, source }) => {
            if ('selectedNodes' in data) {
                this.updateSelected.bind(this)(data.selectedNodes, false);
            }
        });
    }

    updateSelected(selectedNodes, triggerEvent = true){
        if (selectedNodes) {
            this.setState({match: {params: {nodeId: selectedNodes}}})
        } else {
            this.setState({match: undefined});
        }
        if (triggerEvent) {
            console.log(`[map] Posting update: ${selectedNodes}`);
            window.parent.postMessage({selectedNodes: selectedNodes}, "*");
        }
    }

    render() {
        return (
            <Provider store={store}>
                <div className="helvetica" style={{width: "100%"}}>
                    <MapView match={this.state.match} updateSelected={this.updateSelected.bind(this)}/>
                </div>
            </Provider>
        );
    }
}

export default Map;
